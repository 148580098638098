<template>
    <div class="content-wrapper">
      <!-- <div v-if="loader" class="page_loader"></div> -->
      <!-- Content Header (Page header) -->
      <div class="content-header" style="font-size:16px;">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">{{ $t('globalTrans.dashboard') }}</h1>
            </div><!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">{{ $t('globalTrans.home') }}</a></li>
                <li class="breadcrumb-item active">{{ $t('globalTrans.dashboard') }}</li>
              </ol>
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid dashboard-card">
          <div class="card" v-if="areas.length > 0">
            <div class="card-header">
              <div class="row">
                  <div class="col-md-12"><h4>{{ $t('globalTrans.selected_area') }}</h4></div>
              </div>
            </div>
            <div class="card-body">
              {{ areas }}
            </div>
          </div>

          <div class="card mt-20">
            <div class="card-header">
              <div class="row">
                  <div class="col-xl-2 col-md-2 col-sm-4 col-xs-12">
                      <div class="form-group">
                          <label for="start_date">{{ $t('globalTrans.startDate') }} </label>
                          <input type="date" id="start_date" v-model="summarySearch.start_date" class="form-control" />
                      </div>
                  </div>
                  <div class="col-xl-2 col-md-2 col-sm-4 col-xs-12">
                      <div class="form-group">
                          <label for="end_date">{{ $t('globalTrans.endDate') }} </label>
                          <input type="date" id="end_date" v-model="summarySearch.end_date"  class="form-control" />
                      </div>
                  </div>
                  <div class="col-xl-2 col-md-2 col-sm-4 col-xs-12">
                      <div class="form-group">
                          <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchSummaryData" style="margin-top:30px;"><i class="fa fa-search"></i> Filter</button>
                      </div>
                  </div>
              </div>
            </div>
            <div class="card-body">
              
              <b-overlay :show="loader">
                <div class="table-responsive">
                  <table class="table table-bordered table-sm">
                      <thead style="width:100%">
                          <tr>
                              <th colspan="4"><h4>{{ $t('globalTrans.todays_summary') }}</h4></th>
                          </tr>
                          <tr class="text-center">
                              <th>All Completed</th>
                              <th>All Incompleted</th>
                              <th>Total Collected Amount (Tk)</th>
                          </tr>
                      </thead>
                      <tbody v-if="todayData" class="text-center">
                          <td>{{ todayData ? todayData.today_completed : 0 }}</td>
                          <td>{{ todayData ? todayData.today_incompleted : 0 }}</td>
                          <td>{{ todayData ? todayData.total_collected : 0 }}</td>
                      </tbody>
                  </table>
                </div>
              </b-overlay>

              <b-overlay :show="loader">
                <div class="table-responsive">
                  <table class="table table-bordered table-sm">
                      <thead style="width:100%">
                          <tr>
                              <th colspan="7"><h4>{{ $t('globalTrans.total_summary') }}</h4></th>
                          </tr>
                          <tr class="text-center">
                              <th>All Completed</th>
                              <th>All Incompleted</th>
                              <th>Total Collected Amount (Tk)</th>
                          </tr>
                      </thead>
                      <tbody v-if="totalData">
                        <tr class="text-center">
                          <td>{{ totalData ? totalData.total_completed : 0 }}</td>
                          <td>{{ totalData ? totalData.total_incompleted : 0 }}</td>
                          <td>{{ totalData ? totalData.total_collected : 0 }}</td>
                        </tr>
                      </tbody>
                  </table>
                </div>
              </b-overlay>
            </div>
          </div>

          <div class="card mt-20">
            <div class="card-header">
              <div class="row">
                   <div class="col-md-2">
                        <div class="form-group">
                            <label for="tracking_id">{{ $t('globalTrans.order_id') }}</label>
                            <input type="text" id="tracking_id" class="form-control" v-model="orderSearch.id" placeholder="Order ID">
                        </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                          <label for="contact">{{ $t('globalTrans.contact') }}</label>
                          <input type="text" id="contact" v-model="orderSearch.contact" placeholder="Name, Mobile, Address" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                          <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchOrder" style="margin-top:30px;"><i class="fa fa-search"></i> Filter</button>
                      </div>
                  </div>
              </div>
            </div>
            <div class="card-body">
              <b-overlay :show="orderLoader">
                <div class="table-responsive">
                  <table class="table table-bordered table-sm">
                      <thead style="width:100%">
                          <tr>
                              <th>{{ $t('globalTrans.order_id') }}</th>
                              <th>{{ $t('globalTrans.name') }}</th>
                              <th>{{ $t('globalTrans.mobile') }}</th>
                              <th>{{ $t('globalTrans.date') }}</th>
                              <th>{{ $t('globalTrans.price') }}</th>
                              <th>{{ $t('globalTrans.collected') }}</th>
                              <th>{{ $t('globalTrans.area') }}</th>
                              <th>{{ $t('globalTrans.status') }}</th>              
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="(item ,key) in orders" :key="key">
                              <td @click="orderLog(item.logs)">{{ item.id }}</td>
                              <td>{{ item.name }}</td>
                              <td>{{ item.mobile }}</td>
                              <td>{{ item.date }}</td>
                              <td>{{ item.price }}</td>
                              <td>{{ item.collected }}</td>
                              <td>{{ item.area ? item.area.name : '' }}</td>
                              <td><span :class="'status-'+ item.status">{{ getStatus(item.status) }}</span></td>  
                          </tr>
                      </tbody>
                  </table>
                </div>
              </b-overlay>
            </div>
          </div>

        </div><!-- /.container-fluid -->
      </section>
      <!-- /.content -->

      <div v-if="orderLogModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Order Log</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Action By</th>
                                <th>Action</th>
                                <th>Date & Time</th>
                            </tr>
                            <tr v-for="(log, index) in order_logs" :key="index">
                                <td>{{ log.operation_by }}</td>
                                <td>{{ log.operation }}</td>
                                <td>{{ log.created_at | dateformat }} at {{ log.created_at | timeformat }}</td>
                            </tr>
                        </table>                      
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-danger" @click="cancelModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>
<script>
  import config from '@/config'
  import moment from 'moment'

  export default {
    name:'Dashboard',
    data() {
      return {
        yesterday: moment().subtract(1,'d').format('YYYY-MM-DD'),
        today: moment().format('YYYY-MM-DD'),
        orderLoader: false,
        orderLogModal: false,
        loader: false,
        areas: [],
        orders: [],
        orderSearch : {
          id: '',
          contact: ''
        },
        summarySearch : {
          start_date: moment().subtract(90,'d').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD')
        },
        orderData: [],
        order_logs: [],
        todayData: null,
        totalData: null
      }
    },
    created () {
      this.searchSummaryData()
      this.getAssignedAreaList()
    },
    computed : {
      authUser () {
        return this.$store.state.authUser
      },
      areaList () {
        return this.$store.state.commonObj.areaList
      }
    },
    methods: {
      searchOrder () {
        this.getOrderData()
      },
      searchSummaryData () {
        this.getTodaySummary()
        this.getLastdaySummary()
      },
      async getOrderData () {
        this.orderLoader = true
        const params = Object.assign(this.orderSearch, { rider_id: this.authUser.id })
        const response = await config.getData('/rider/dashboard/track', params)
        this.orderLoader = false
        if (response.status === 200) {
            this.orders = response.data  
        } else {
            this.orders = []
        }    
      },
      getTodaySummary () {
        this.loader = true
        const params = Object.assign(this.summarySearch, { rider_id: this.authUser.id })
        config.getData('/rider/dashboard/today-summary', params)
        .then((response) => {
          this.loader = false
          this.todayData = response.data
        });
      },
      getLastdaySummary () {
        this.loader = true
        const params = Object.assign(this.summarySearch, { rider_id: this.authUser.id })
        config.getData('/rider/dashboard/total-summary', params)
        .then((response) => {
          this.loader = false
          this.totalData = response.data
        });
      },
      getStatus (StatusId) {
        const tmpStatus = this.$store.state.statusList.find(status => status.id === StatusId)
        return this.$t(tmpStatus.text)
      },
      getAssignedAreaList () {
        const areaIds = JSON.parse(this.authUser.area_id)
        let areaList = []
        areaIds.map(item => {
          const area = this.areaList.find(el => el.id === item)
          areaList.push(typeof area != 'undefined' ? area.text : '')
        })
        this.areas = areaList.join(', ')
      },
      orderLog (logs) {
        this.order_logs = logs 
        this.orderLogModal = true
      },
      cancelModal () {
        this.orderLogModal = false
      }
    }
  }
</script>